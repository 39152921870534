import * as API from "@/api/request";
import store from "@/store";

const comUrl = `${store.getters.deviceInfo.blockUrl}/`;

export function txStatistics(d = 45, group = 1) {
  return API.get(`${comUrl}tx/statistics/${group}?d=${d}`);
}

export function indexTxs(size = 5, group = 1) {
  return API.get(`${comUrl}index/txs/${group}?size=${size}`);
}

export function blockH(keyword, group = 1) {
  return API.get(`${comUrl}block/${keyword}/${group}`);
}

export function tradingHash(keyword, group = 1) {
  return API.get(`${comUrl}tx/${keyword}/${group}`);
}
