<template>
  <section v-loading="loading" class="ls-container">
    <BreadCrump :breadcrumbList="breadcrumbList" />

    <div class="main-section">
      <div class="overview-wrap">
        <div class="overview-item">
          <div class="item-wrap">
            <div class="ls-title">数字资产服务</div>
            <div class="contents">
              <div class="txt-number">
                <span class="txt">{{ publishNum }}</span>
                <span v-if="assetData.asserCount > 0" class="num">{{ assetData.asserCount }}</span>
              </div>
              <div class="ls-btns" @click="routeLink('ServiceOverview')">
                前往查看
              </div>
            </div>
            <div class="hint">
              每一份资产的数字化发行都会在链上成功部署独立的智能合约
            </div>
          </div>
        </div>
        <div class="overview-item">
          <div class="item-wrap">
            <div class="ls-title">数字资产保全服务</div>
            <div class="contents">
              <div class="txt-number">
                <span class="txt">服务正在升级中，即将与您见面</span>
              </div>

              <!-- <div class="txt-number">
                <span class="txt">普通存证</span>
                <span class="num">0</span>
              </div>
              <div class="txt-number">
                <span class="txt">司法存证</span>
                <span class="num">0</span>
              </div>
              <div class="ls-btns" @click="routeLink()">前往查看</div> -->
            </div>
          </div>
        </div>
        <div v-if="chargeModel != 2" class="overview-item">
          <div class="item-wrap">
            <div class="ls-title">我的燃料余额</div>
            <div class="contents">
              <div class="txt-number">
                <span class="num">{{ formatNumber(assetData.gas) }}</span>
              </div>
              <div class="ls-btns" @click="routeLink('FuelManage')">前往查看</div>
            </div>
          </div>
        </div>
      </div>

      <div class="browser-data">
        <div class="ls-title">零数开放许可链区块链浏览器实时数据</div>
        <div class="last-list">最新交易清单</div>
        <Table :baseData="tableData" :tableHeader="tableHeader" :align="'center'">
        </Table>
      </div>

      <div class="deal-chart">
        <div class="ls-title">零数开放许可链近期交易数</div>
        <StackedLine :xData="xData" :yData="yData" />
      </div>
    </div>
  </section>
</template>
<script>
import { mapGetters, mapMutations } from "vuex";
import Empty from "@/components/common/Empty.vue";
import BreadCrump from "@/components/common/BreadCrump.vue";
import StackedLine from "@/components/common/StackedLine.vue";
import { assetTotal } from "@/api/interface/assets.js";
import { formatNumber } from "@/utils/common.js";
import { txStatistics, indexTxs } from "@/api/interface/outsideQuery.js";
import dayjs from "dayjs";

export default {
  components: {
    Empty,
    BreadCrump,
    StackedLine,
  },
  data() {
    return {
      breadcrumbList: [{ menuName: "工作台首页" }],
      tableData: [],
      tableHeader: this.$tableHeader.overview.lastList,
      assetData: {},
      loading: true,
      xData: [],
      yData: [],
    };
  },
  computed: {
    ...mapGetters(["userInfo", "deviceInfo"]),
    publishNum() {
      return this.assetData.asserCount > 0 ? `已发行` : "暂无数字资产发行记录";
    },
    chargeModel() {
      return this.userInfo.shop && this.userInfo.shop.chargeModel; 
    }
  },
  methods: {
    formatNumber,
    routeLink(routeName) {
      if (!routeName) {
        this.$message.info(this.$CommonMsg["building"]);
        return;
      }
      this.$router.push({ name: routeName });
    },
    setDealChart() {},
    getAssetTotal() {
      assetTotal()
        .then((data) => {
          this.assetData = data;
        })
        .finally(() => {
          this.loading = false;
        });
    },
    getLastList() {
      indexTxs().then((data) => {
        this.tableData = data || [];
      });
    },
    getTransactionNumber() {
      txStatistics().then((data) => {
        data && data.forEach((item) => {
          this.xData.push(dayjs(item.day).format("MM-DD"));
          this.yData.push(item.txs);
        });
      });
    },
  },
  created() {
    this.getAssetTotal();
    this.getLastList();
    this.getTransactionNumber();
  },
  mounted() {},
};
</script>
<style lang="scss" scoped>
@import "@/scss/el/_table.scss";
@import "@/scss/index.scss";
</style>
